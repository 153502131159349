<template>
  <!-- 积分商城 提交订单 -->
  <div class="content-page">
    <b-card class="mb-3 my-card">
      <div class="breadcrumb">
        <el-breadcrumb separator-class="el-icon-arrow-right">
          <el-breadcrumb-item :to="{ path: '/webstore' }">Webstore</el-breadcrumb-item>
          <el-breadcrumb-item :to="{ path: `/shopscoredetails?id=${$route.query.id}`}">Product Page</el-breadcrumb-item>
          <el-breadcrumb-item>Submit Order</el-breadcrumb-item>
        </el-breadcrumb>
      </div>
    </b-card>
    <b-card class="mb-3">
      <div class="row">
        <div class="col">
          <h5>Pickup method</h5>
        </div>
      </div>
      <div class="my_border"></div>
      <div>
        <el-radio v-model="pickup_method" :label="1">Shipping</el-radio>
        <el-radio v-model="pickup_method" :label="2" v-if="orderDetail.is_pickup">Pick up in store</el-radio>
      </div>
    </b-card>

    <b-card class="mb-3" v-if="pickup_method==1">
      <div class="row">
        <div class="col">
          <h5>Shipping address</h5>
        </div>
        <div class="col text-right">
          <a class="cursor-pointer"><span @click="openModal">Add address</span></a>
        </div>
      </div>
      <div class="my_border"></div>

      <div class="row mt-2 my_row" :class="{'default':address_id==v.id}" v-for="(v, i) in user_address" :key="i">
        <div class="col-12 col-md-6">
          <el-radio v-model="address_id" :label="v.id" class="mr-1" :disabled="v.disabled" />
          {{v.address}}, {{v.country}}(Consignee:{{v.consignee_name}})
        </div>
        <div class="col-12 col-md-6">
          <div class="row">
            <div class="col">
              <span>{{v.consignee_phone}}</span>
            </div>
            <div class="col text-right">
              <a class="cursor-pointer"><span @click="openModal(v)">Modify address</span></a>
            </div>
          </div>
        </div>
      </div>
    </b-card>

    <b-card class="mb-3" v-if="pickup_method==2">
      <h4>Picked up in stores</h4>
      <div class="my_border"></div>
      <p class="m-0" v-if="!pickDetail.id">
        Free shipping for store pickup
        <a class="a-border cursor-pointer" @click="$bvModal.show('addressModal')"> Please select the store to pick up
        </a>
      </p>
      <div class="d-flex justify-content-between default p-2" v-else>
        <div class="d-flex align-items-center">
          <input type="radio" checked style="margin-top: -3px;">
          <span class="pl-2 pr-3" style="color: #333;">
            {{pickDetail.address}}(Consignee:{{pickData.consignee_name}})
          </span>
          <span>{{pickData.consignee_phone}}</span>
        </div>
        <a class="cursor-pointer"><span @click="openPickUp">Modify
            store</span></a>
      </div>
    </b-card>

    <b-card class="mb-3">
      <div class="row mt-3">
        <div class="col-2 text-left v-center">
          <el-image :src="orderDetail.product_image" alt="" style="width: 120px;height: 120px;" />
        </div>
        <div class="col-4 text-left v-center">
          <p class="title mb-sm-5">{{orderDetail.product_name}}</p>
          <div class="d-flex flex-wrap" style="color: #666;">
            <div v-for="(v, i) in orderDetail.product_attr" :key="i" class="d-flex">
              <p>{{v.attr_name}} : {{v.attr_value}}</p>
              <span v-if="i!=orderDetail.product_attr.length-1">；</span>
            </div>
          </div>
        </div>
        <div class="col-3 text-center v-center">
          <p class="m-0">{{orderDetail.points}} Points*{{orderDetail.quantity}}</p>
        </div>
        <div class="col-3 text-right v-center">
          <p class="m-0">{{orderDetail.points*orderDetail.quantity}} Points</p>
        </div>
      </div>
      <div class="my_border"></div>
      <div class="row">
        <div class="col-12 col-md-7">
          <input class="int-area" type="text" v-model="remark" placeholder="Leave a message to the seller" />
        </div>
        <div class="col-12 col-md-5 text-md-right">
          <p class="price" style="color: #999;">
            Freight:<span style="color: #333"> Free Shipping</span>
          </p>
          <p class="price" style="color: #999;">
            Total:<strong style="color: #333">{{orderDetail.points*orderDetail.quantity}} Points</strong>
          </p>
        </div>
      </div>
    </b-card>
    <b-card class="mb-3 ">
      <div class="text-right">
        <button class="btn order_btn" @click="pointsOrderSubmission">Place order</button>
      </div>
    </b-card>

    <!-- 自提选择门店弹框 -->
    <b-modal id="addressModal" centered title="Select the pick-up point (store pick-up)" hide-footer>
      <div class="add_address">
        <div class="line"></div>
        <el-select v-model="value" style="width: 330px;" placeholder="All" @change="filterAddress" clearable>
          <el-option v-for="(v,i) in orderDetail.pick_country_list" :key="v.country_id" :label="v.country"
            :value="v.country_id">
          </el-option>
        </el-select>
        <div class="address_content" v-if="pickList.length">
          <div class="address_item d-flex" v-for="(v, i) in pickList" :key="i">
            <el-radio v-model="pickParams.pickup_id" :label="v.id" class="mr-3" style="margin-top: 7px;">
            </el-radio>
            <div class="address_detail d-flex flex-column">
              <span class="title">{{i+1}}.{{v.branch_name}}</span>
              <span>Address: {{v.address}}</span>
              <span>Business hours: {{v.business_hours}}</span>
            </div>
          </div>
        </div>
        <div class="information d-flex justify-content-between mt-3">
          <div>
            <span>* Consignee: </span>
            <el-input v-model="pickParams.input_name" style="width: 233px;"></el-input>
          </div>
          <div class="phone_box">
            <span>* Phone number: </span>
            <el-input v-model="pickParams.input_phone" style="width: 233px;">
              <el-select v-model="pickParams.phone_prefix" slot="prepend" placeholder="">
                <el-option v-for="item in areaCodeList" :key="item.code_id" :label="item.code_name"
                  :value="item.code_name">
                </el-option>
              </el-select>
            </el-input>
          </div>
        </div>
        <div class="link d-flex pt-3">
          <el-checkbox v-model="pickParams.checked"></el-checkbox>
          <span class="ml-2">I have read and agreed to the<a class="a-border cursor-pointer">Store self delivery
              notice</a>
          </span>
        </div>
        <div class="line mt-2"></div>
        <div class="text-right pt-3">
          <button class="btn cancel" @click="clearPickData">Cancel</button>
          <button class="btn" @click="pickConfirm">Confirm</button>
        </div>
      </div>
    </b-modal>

    <!-- 添加地址弹框 -->
    <b-modal id="addressShip" centered title="Edit Shipping address" hide-footer>
      <div class="add_address_ship">
        <div class="row">
          <div class="col-6">
            <h6>Consignee</h6>
            <el-input class="int" type="text" v-model="params.consignee_name" />
          </div>
          <div class="col-6">
            <h6>Phone number</h6>
            <div class="code_phone">
              <el-input class="int" type="text" v-model="params.consignee_phone" />
              <el-select v-model="params.phone_prefix" style="width: 100%;" filterable clearable placeholder="">
                <el-option v-for="item in areaCodeList" :key="item.code_id" :label="item.code_name"
                  :value="item.code_name">
                </el-option>
              </el-select>
            </div>
          </div>
        </div>
        <div>
          <h6 class="postcode">Postcode</h6>
          <el-input class="int w-50" type="text" v-model="params.post_code" />
        </div>
        <div class="country">
          <h6>Country</h6>
          <el-select v-model="countryData" style="width: 100%;" filterable clearable placeholder="">
            <el-option v-for="item in countryList" :disabled="item.disabled" :key="item.country_id"
              :label="item.country" :value="`${item.country}|${item.country_id}`">
            </el-option>
          </el-select>
        </div>
        <div class="mb-3">
          <h6>Detailed address</h6>
          <el-input type="textarea" v-model="params.address" resize="none"></el-input>
        </div>
        <!-- <div class="mb-5 switch">
          <el-switch v-model="is_default" active-text="Set as default address"></el-switch>
        </div> -->
        <hr />
        <div class="text-right">
          <button class="btn" @click="handleSave">Save</button>
        </div>
      </div>
    </b-modal>
  </div>
</template>

<script>
  import areaCodeList from '@/assets/js/code.js'
  export default {
    data() {
      return {
        pickup_method: 1,
        value: '',
        remark: '',
        orderDetail: {},
        user_address: [],
        is_default: false,
        params: {
          consignee_name: '',
          consignee_phone: '',
          post_code: '',
          address: '',
          phone_prefix: ''
        },
        countryList: [],
        countryData: '',
        country_id: '',
        areaCodeList,
        pickList: [],
        pickParams: {
          pickup_id: '',
          input_name: "",
          input_phone: "",
          phone_prefix: "",
          checked: '',
        },
        pickData: {
          consignee_name: "",
          consignee_phone: "",
          phone_prefix: ""
        },
        pickDetail: {},
        address_id: '',
      };
    },

    created() {
      this.orderDetail = JSON.parse(sessionStorage.getItem("order_detail"))
      // console.log(this.orderDetail);
      this.countryList = this.orderDetail.delivery_range
      this.pickList = JSON.parse(JSON.stringify(this.orderDetail.pick_list))
      this._myAddresses()
    },

    methods: {
      // 判断收货地址是否可选
      _handlerAddress() {
        let delivery_range = JSON.parse(JSON.stringify(this.orderDetail.delivery_range))
        let rangeList = delivery_range.map(v => v.country_id)
        this.user_address.map(v => {
          if (!rangeList.includes(v.country_id)) {
            this.$set(v, 'disabled', true)
          }
        })
      },

      // 打开收货地址弹框
      openModal(v) {
        this.$bvModal.show('addressShip')
        if (v) {
          Object.keys(this.params).forEach(item => {
            this.params[item] = v[item]
          })
          this.countryData = v.country
          this.country_id = v.country_id
          this.is_default = Boolean(v.is_default)
          this.id = v.id
        } else {
          Object.keys(this.params).forEach(item => {
            this.params[item] = ""
          })
          this.country_id = ""
          this.countryData = ""
          this.is_default = false
          this.id = ""
        }
      },

      // 打开自提弹框
      openPickUp() {
        this.pickParams.pickup_id = this.pickDetail.id
        this.pickParams.input_name = this.pickData.consignee_name
        this.pickParams.input_phone = this.pickData.consignee_phone
        this.pickParams.phone_prefix = this.pickData.phone_prefix
        this.$bvModal.show('addressModal')
      },

      // 清空自提数据 
      clearPickData() {
        this.value = ""
        this.pickList = JSON.parse(JSON.stringify(this.orderDetail.pick_list))
        Object.keys(this.pickParams).forEach(item => {
          this.pickParams[item] = ""
        })
        this.$bvModal.hide('addressModal')
      },

      // 收货地址列表
      async _myAddresses() {
        let res = await this.$http.myAddresses()
        if (res.status == 200) {
          this.user_address = res.data
          this._handlerAddress()
        }
      },

      // 添加收货地址
      async handleSave() {
        if ((!this.params.consignee_name ||
          !this.params.consignee_phone ||
          !this.countryData ||
          !this.params.address)
        ) {
          return this.$message.warning('Please fill in the complete information !')
        }

        let array = String(this.countryData).split('|')
        let country, country_id, res
        if (array.length == 2) {
          country = this.countryData.split('|')[0]
          country_id = this.countryData.split('|')[1]
        } else {
          country = this.countryData
          country_id = this.country_id
        }
        let data = {
          ...this.params,
          country,
          country_id,
          is_default: Number(this.is_default)
        }

        if (this.id) {
          // 修改
          res = await this.$http.editAddress({ ...data, id: this.id })
        } else {
          // 添加
          res = await this.$http.addAddress(data)
        }

        if (res.status == 200) {
          this.$bvModal.hide('addressShip')
          this.user_address = res.data
          this._handlerAddress()
        }
      },

      // 筛选自取地址
      filterAddress(value) {
        if (value) {
          let allArray = JSON.parse(JSON.stringify(this.orderDetail.pick_list))
          this.pickList = allArray.filter(v => value == v.country_id)
        } else {
          this.pickList = JSON.parse(JSON.stringify(this.orderDetail.pick_list))
        }
      },

      // 选择自取地址
      pickConfirm() {
        if (!this.pickParams.pickup_id) return this.$message.warning('Please select a pick-up store !')
        if (!this.pickParams.input_name) return this.$message.warning('Please fill in the consignee !')
        if (!this.pickParams.input_phone) return this.$message.warning('Please fill in the telephone number !')
        if (!this.pickParams.phone_prefix) return this.$message.warning('Please select an area code !')
        if (!this.pickParams.checked) return this.$message.warning('Please check the agreement !')
        this.pickData.consignee_name = this.pickParams.input_name
        this.pickData.consignee_phone = this.pickParams.input_phone
        this.pickData.phone_prefix = this.pickParams.phone_prefix
        this.pickList.map(v => {
          if (v.id == this.pickParams.pickup_id) this.pickDetail = v
        })
        this.value = ""
        this.pickList = JSON.parse(JSON.stringify(this.orderDetail.pick_list))
        this.$bvModal.hide('addressModal')
      },

      // 选择自取地址
      async pointsOrderSubmission() {
        let commentData = {
          pickup_method: this.pickup_method,
          id: this.$route.query.id,
          price: this.orderDetail.points * this.orderDetail.quantity,
          attr_price_id: this.orderDetail.attr_price_id,
          quantity: this.orderDetail.quantity,
          remark: this.remark,
          time_zone_name: Intl.DateTimeFormat().resolvedOptions().timeZone,
        }
        let data
        if (this.pickup_method == 1) {
          if (!this.address_id) return this.$message.warning('Please select a shipping address !')
          data = {
            ...commentData,
            pickup_id: this.address_id,
          }
        } else {
          if (!this.pickDetail.id) return this.$message.warning('Please select a pick-up point !')
          data = {
            ...commentData,
            ...this.pickData,
            pickup_id: this.pickDetail.id
          }
        }
        let res = await this.$http.pointsOrderSubmission(data)
        if (res.status == 200) {
          this.$router.push(`/exchangeResult?type=1`)
        } else {
          this.$router.push(`/exchangeResult?type=2&msg=${res.message}`)
        }
      }
    },
  };
</script>

<style lang="scss" scoped>
  .content-page {
    color: #333;
  }

  .my-card {
    .card-body {
      padding: 0;
    }
  }

  .breadcrumb {
    height: 60px;
    margin-bottom: 0;
    display: flex;
    align-items: center;
    background-color: #fff;

    ::v-deep span.el-breadcrumb__inner.is-link {
      color: #50B5FF;
      font-weight: 500;
    }
  }

  .my_border {
    width: 100%;
    height: 1px;
    background: #eee !important;
    margin: 20px 0;
  }

  .default {
    background: #f5fbff;
    border: 1px solid #50b5ff;
  }

  .my_row {
    padding: 8px 0;
    box-sizing: border-box;

    ::v-deep .el-radio__label {
      display: none;
    }
  }

  .int-area {
    width: 80%;
    height: 80px;
    padding: 0 0 40px 10px;
    background: #f8f8f8;
    border: 1px solid #f1f1f1;
    border-radius: 10px;
  }

  .a-border {
    border-bottom: 1px solid #50b5ff;
    margin-left: 10px;
  }

  .v-center {
    margin: auto 0;
  }

  .btn {
    width: 100px;
    height: 40px;
    color: #ffffff;
    background: #50b5ff;
    border-radius: 4px;
  }

  .order_btn {
    width: 120px;
    height: 45px;
    border-radius: 5px;
  }

  .cancel {
    color: #999999;
    background-color: #fff;
  }

  ::v-deep .modal-dialog.modal-md.modal-dialog-centered {
    max-width: 812px !important;
  }

  ::v-deep .modal-header {
    border-bottom: 0;
    padding: 27px 40px 22px 30px;
  }

  ::v-deep .modal-body {
    padding: 0 40px 40px 30px;
  }

  .address_content {
    ::v-deep .el-radio__label {
      display: none;
    }
  }

  .add_address {
    .line {
      height: 1px;
      border: 1px dashed #eee;
      margin-bottom: 10px;
    }

    .address_content {
      max-height: 420px;
      margin-top: 10px;
      margin-bottom: 20px;
      border: 1px solid #e6e6e6;
      overflow-y: auto;
      padding: 20px 10px;

      span {
        color: #999;
        font-size: 14px;
      }

      .title {
        color: #333;
        font-size: 16px;
      }
    }

    ::v-deep .phone_box {
      .el-select .el-input {
        width: 95px;
      }
    }
  }

  .switch {
    ::v-deep span {
      color: #3F414D;
      font-weight: normal;
    }
  }

  .add_address_ship {
    color: #3F414D;

    h6 {
      display: flex;
      position: relative;
      margin: 15px 0;

      &::after {
        display: block;
        content: '*';
        color: red;
        margin-left: 3px;
      }
    }

    .postcode::after {
      content: '';
    }

    .int {
      ::v-deep input {
        width: 100%;
        height: 45px;
        border-radius: 5px;
      }
    }

    ::v-deep textarea {
      width: 100%;
      height: 85px;
      border-radius: 5px;
    }

    ::v-deep .country {
      input {
        height: 45px !important;
      }
    }

    .code_phone {
      position: relative;

      .int {
        ::v-deep input {
          text-indent: 120px;
        }
      }

      ::v-deep .el-select {
        position: absolute !important;
        left: 0;
        width: 120px !important;
        height: 45px !important;
        padding: 2px;

        input {
          width: 120px !important;
          border: none;
        }
      }
    }
  }
</style>